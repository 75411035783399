define(['app', 'siteObj', '$window'], (app, siteObj, $window) => {

  const DATA_PRODUCT_ID = 'data-product-id';
  const DATA_PRODUCT_PRICE = 'data-product-price';
  const DATA_PRODUCT_TITLE = 'data-product-title';
  const DATA_PRODUCT_CATEGORY = 'data-product-category';
  const DATA_SELECTED = 'data-selected';
  const DATA_LIST_TYPE = 'data-list-type';
  const DATA_CUSTOMER_STATE = 'data-customer-state';
  const DATA_UNAVAILABLE = 'data-unavailable';
  const DATA_SHOW_MODAL = 'data-show-modal';
  const DATA_WISHLIST_PAGE = 'data-wishlist-page';
  const eventCategoryTracking = 'Wishlist Engagement';
  const DATA_TRANSLATION_ADDED = 'data-translation-added';
  const DATA_TRANSLATION_REMOVED = 'data-translation-removed';
  const DATA_BRAND = 'data-product-brand';

  const productAddToWishlist = () => {
    const component = {};

    const _subscribeChannels  = {
      wishlistsTooltipModal: 'wishlistsTooltipModal/update'
    };

    const _init = (element, userDriven) => {
      component.element = element;

      if (element) {
        component.updateAvailabilityStateOfComponent();
        component.partOfAlternatives = element.parentNode.classList.contains('productAlternatives_productAddToWishlist');
        component.button = element.querySelector('.productAddToWishlist_button_default');
        component.popupwindow = element.querySelector('.productAddToWishlist_popup');
        component.loginbutton = element.querySelector('.productAddToWishlist_login_button');
        component.closeButton = element.querySelector('.productAddToWishlist_popup_close');
        component.basketPageButtonText = element.querySelector('.productAddToWishlist_basketPageButtonText');
        component.otherPagesButtonIcons = element.querySelector('.productAddToWishlist_buttonIcon');
        component.deleteButton = element.parentNode.querySelector('.productAddToWishlist_deleteButton');
        component.heartIcon = component.element.querySelector('.heart');
        component.attrs = {
          productId: element.getAttribute(DATA_PRODUCT_ID),
          productPrice: element.getAttribute(DATA_PRODUCT_PRICE),
          productTitle: element.getAttribute(DATA_PRODUCT_TITLE),
          productCategory: element.getAttribute(DATA_PRODUCT_CATEGORY),
          showWishlistsTooltipModal: element.getAttribute(DATA_SHOW_MODAL),
          wishlistPage: element.getAttribute(DATA_WISHLIST_PAGE),
          brand: element.getAttribute(DATA_BRAND)
        };

        component.translations = {
          added: element.getAttribute(DATA_TRANSLATION_ADDED),
          removed: element.getAttribute(DATA_TRANSLATION_REMOVED),
        };

        if (!userDriven) {
          app.subscribe('productAddToWishlist/update', component.updateProductAddToWishlist);

          if (component.partOfAlternatives) {
            app.subscribe('productAddToWishlist/changeAlternativesWishlist', component.replaceDom);
          } else {
            app.subscribe('productAddToWishlist/changeProductWishlist', component.replaceDom);
          }
        }

        component.addListeners();
        component.hostName = window.location.hostname;
        component.pathName = window.location.pathname;
        component.requestParameters = '';
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.has("sourceProduct")) {
          component.requestParameters = `?sourceProduct=${queryParams.get("sourceProduct")}`
        }

        component.updateWishlistsTooltipModal();
      }

      return component;
    };

    const _addToWishlistNotLoggedInEvent = () => {

      if (component.element.getAttribute(DATA_CUSTOMER_STATE) !== 'LOGGED_IN')
        app.publish('tracking/record', eventCategoryTracking, 'clicked add to wishlist | logged out', component.attrs.productId);
    };

    const _addListeners = () => {
      component.button.addEventListener('click', component.buttonOnClick.bind(null, component.button));
      component.button.addEventListener('click', component.addToWishlistNotLoggedInEvent);
      component.closeButton.addEventListener('click', component.buttonOnClick.bind(null, component.button));
      component.deleteButton.addEventListener('click', component.deleteOnClick.bind(null, component.button));
      component.loginbutton.addEventListener('click', component.loginOnClick);

      if (component.element.getAttribute(DATA_CUSTOMER_STATE) === 'LOGGED_IN')
        component.button.removeAttribute('aria-expanded');
    };

    const _closePopup = () => {
      component.button.setAttribute('aria-expanded', 'false');
      component.popupwindow.classList.remove('productAddToWishlist_popup_show');

      document.removeEventListener('keydown', component.keyboardHandler);
      window.removeEventListener('click', component.checkTarget);

      app.publish('tracking/record', eventCategoryTracking, 'close login pop-up', component.attrs.productId);
    };

    const _buttonOnClick = (button) => {

      const listType = button.getAttribute(DATA_LIST_TYPE);
      const selected = button.getAttribute(DATA_SELECTED);
      const customerState = component.element.getAttribute(DATA_CUSTOMER_STATE);

      if (customerState !== 'LOGGED_IN') {

        if (component.popupwindow.classList.contains('productAddToWishlist_popup_show')) {
          app.publish('tracking/record', eventCategoryTracking, 'close login pop-up', component.attrs.productId);
        }

        component.toggleWishlistTooltip();

      } else {

        if (selected === 'true') {
          app.publish('tracking/record', eventCategoryTracking, 'removed from wishlist', component.attrs.productId);
          return component.removeFromList(listType)
            .then(component.fetchDomForRemove)
            .then(component.replaceDom)
            .then(component.announceRemoved)
            .catch(console.error);
        } else {
          app.publish('tracking/record', eventCategoryTracking,
            'added to wishlist', component.attrs.productId);
          if(siteObj.config.useGa4EnhancedEcom === true) {
            const itemsObjectArray = [{
              'item_name': component.attrs.toString().replace(/&#039;|'/g, ""),
              'item_id' : component.attrs.productId.toString(),
              'price': parseFloat(component.attrs.productPrice.substring(1)),
              'list': window.dataLayer[0].pageTitle,
              'item_category' : component.attrs.productCategory,
              'brand': component.attrs.brand
            }];
            app.publish('ga4tracking/record', 'addToWishlist', 'add_to_wishlist', siteObj.currencyType, itemsObjectArray);
          }
          return component.addToList(listType)
            .then(component.fetchDomForAdd)
            .then(component.replaceDom)
            .then(component.updateWishlistsTooltipModal)
            .then(component.animateHeartIcon)
            .then(component.announceAdded)
            .then(component.criteoBeacon)
            .catch(console.error);
        }
      }
    };

    const _toggleWishlistTooltip = () => {
      const closing = component.popupwindow.classList.contains('productAddToWishlist_popup_show');

      component.popupwindow.classList.toggle('productAddToWishlist_popup_show');

      if (closing) {
        component.button.setAttribute('aria-expanded', 'false');
        component.button.focus();
        component.closePopup();
      } else {
        component.button.setAttribute('aria-expanded', 'true');
        component.loginbutton.focus();
        document.addEventListener('keydown', component.keyboardHandler);
        window.addEventListener('click', component.checkTarget);
      }
    };

    const _checkTarget = (event) => {
      if (!component.element.contains(event.target) && component.popupwindow.classList.contains('productAddToWishlist_popup_show')) {
        component.closePopup();
      }
    };

    const _loginOnClick = (e) => {
      e.preventDefault();
      app.publish('tracking/record', eventCategoryTracking, 'clicked login link', component.attrs.productId);
      const listType = component.button.getAttribute(DATA_LIST_TYPE);
      const requestParameter = component.getReqParam('recentlyDeleted');
      const redirectUrl = siteObj.siteURL
        + 'wishlist.account?prodId=' + component.attrs.productId
        + '&type=' + listType
        + '&wishlisted=' + requestParameter
        + '&origin=' + encodeURIComponent(component.hostName + component.pathName + component.requestParameters)
        + '&fromWishlist=true';
      window.location.href = redirectUrl;
    };

    const _fetchDomForRemove = () => {
      return new Promise((res, rej) => app.ajax.get({
        url: `/${component.attrs.productId}.addToWishlist` + '/?isInWishlist=false',
        success: res,
        error: rej,
      }));
    };

    const _fetchDomForAdd = () => {
      return new Promise((res, rej) => app.ajax.get({
        url: `/${component.attrs.productId}.addToWishlist` + '/?isInWishlist=true',
        success: res,
        error: rej,
      }));
    };

    const _replaceDom = (response, noPublish) => {
      if (!noPublish) {
        if (component.partOfAlternatives) {
          app.publish('productAddToWishlist/changeProductWishlist', response, true);
        } else {
          app.publish('productAddToWishlist/changeAlternativesWishlist', response, true);
        }
      }

      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const newElement = parent.querySelector('[data-component=productAddToWishlist]');
      component.init(newElement, true);
    };

    const _addToList = (type) => {
      let mapCategoriesToSkus = 'false';
      let endpoint = `/${type}/${mapCategoriesToSkus}/addTo.collections`;

      return new Promise((res, rej) => app.ajax.post({
        url: endpoint + '?fromWishlist=true&productID=' + component.attrs.productId,
        send: [component.attrs.productId],
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: res,
        error: rej
      }));
    };

    const _removeFromList = (type) => {
      return new Promise((res, rej) => app.ajax.call({
        url: `/${type}/removeFrom.collections?fromWishlist=true&productID=` + component.attrs.productId,
        send: [component.attrs.productId],
        type: 'DELETE',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: res,
        error: rej
      }));
    };

    const _deleteOnClick = (button) => {
      app.publish('tracking/record', eventCategoryTracking, 'removed from wishlist', component.attrs.productId);
      const actionType = button.getAttribute(DATA_LIST_TYPE);
      component.removeFromList(actionType).then(() => window.location.reload());
    };

    const _updateWishlistsTooltipModal = () => {
      if (component.attrs.showWishlistsTooltipModal === 'true' && component.attrs.wishlistPage !== 'true') {
        component.setCookie('wishlistsTooltipModalCookie', 'wishlistsTooltipModalShown');

        $window.setTimeout(() => app.publish(component.subscribeChannels.wishlistsTooltipModal, true), 1000);
      }
    };

    // set cookie name,value and expiration date
    const _setCookie = (name, value) => {
      let path = '/'; // it has to be root for the cookie to be visible on the entire website
      let expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 5);

      document.cookie = name + '=' + value + '; expires=' + expirationDate.toUTCString() + '; path=' + path;
    };

    const _getReqParam = (name) => {
      if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(location.search))
        return decodeURIComponent(name[1]);
    };

    const _redirectConfirmation = () => {
      const redirectUrl = siteObj.siteURL + 'my.basket?wishlisted=' + component.attrs.productId;
      window.location.href = redirectUrl;
    };

    const _updateProductAddToWishlist = (childId, disable) => {
      if (disable) {
        component.updateAvailabilityStateOfComponent(true);
      } else {
        return new Promise(() => app.ajax.get({
          url: '/' + childId + '.addToWishlist',
          success: component.replaceDom,
          error: component.errorHandler,
        }));
      }
    };

    const _errorHandler =  () => {
      console.error(
        'ERROR: Could not retrieve new productAddToWishlist information');
    };

    const _criteoBeacon = () => {
      app.publish('criteo/wishlistBeacon', component.attrs.productId)
    }
    const _animateHeartIcon = () => {
      component.heartIcon && component.heartIcon.classList.add('heartscale');
    };

    const _announceAdded = () => {
      component.button.focus();
      app.publish('accessibility/announce', 'assertive', component.translations.added);
    };

    const _announceRemoved = () => {
      component.button.focus();
      app.publish('accessibility/announce', 'assertive', component.translations.removed);
    };

    const _keyboardHandler = () => {
      switch (event.key) {
        case 'Esc': // IE compatibility
        case 'Escape':
          component.closePopup(event);
          break;
        default:
      }
    };

    const _updateAvailabilityStateOfComponent = (isUnavailable = component.element.getAttribute(DATA_UNAVAILABLE)) => {
      let parent = component.element.parentNode;
      if (isUnavailable === 'true') {
        if (!parent.classList.contains('disabled')) {
          parent.classList.add('disabled');
        }
      } else if (parent.classList.contains('disabled')) {
        parent.classList.remove('disabled');
      }
    };

    component.init = _init;
    component.addListeners = _addListeners;
    component.closePopup = _closePopup;
    component.buttonOnClick = _buttonOnClick;
    component.checkTarget = _checkTarget;
    component.loginOnClick = _loginOnClick;
    component.fetchDomForRemove = _fetchDomForRemove;
    component.fetchDomForAdd = _fetchDomForAdd;
    component.replaceDom = _replaceDom;
    component.addToList = _addToList;
    component.removeFromList = _removeFromList;
    component.deleteOnClick = _deleteOnClick;
    component.criteoBeacon = _criteoBeacon;
    component.updateWishlistsTooltipModal = _updateWishlistsTooltipModal;
    component.setCookie = _setCookie;
    component.getReqParam = _getReqParam;
    component.redirectConfirmation = _redirectConfirmation;
    component.updateProductAddToWishlist = _updateProductAddToWishlist;
    component.errorHandler = _errorHandler;
    component.addToWishlistNotLoggedInEvent = _addToWishlistNotLoggedInEvent;
    component.animateHeartIcon = _animateHeartIcon;
    component.announceAdded = _announceAdded;
    component.announceRemoved = _announceRemoved;
    component.toggleWishlistTooltip = _toggleWishlistTooltip;
    component.subscribeChannels = _subscribeChannels;
    component.keyboardHandler = _keyboardHandler;
    component.updateAvailabilityStateOfComponent = _updateAvailabilityStateOfComponent;

    return component;
  };

  return productAddToWishlist;
});
